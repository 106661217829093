<template>
  <vs-card class="emailCard">
    <h4 class="mb-6 mt-2">Email Details</h4>
    <vs-form>
      <vs-label class="label">Subject Line</vs-label>
      <vs-input class="col-sm-3" v-model="pages.subject"></vs-input>
    </vs-form>
    <div class="editEmail">
      <vs-label class="label">Email Content</vs-label>
    </div>
    <vs-label class="label">Available Linked Content</vs-label>
    <div class="linkedContent">
      <ul>
        <li
          v-for="(keyword, index) in pages.keywords"
          :key="index"
          class="keywords"
        >
          {{ keyword }}
        </li>
      </ul>
    </div>

    <div class="editEmail">
      <vs-label class="label"> Body Content</vs-label>
      <quillEditor :pages="pages"></quillEditor>
    </div>

    <div class="editEmail">
      <vs-label class="label"> Footer Content (Optional)</vs-label>
      <quillEditor :pages="pages" type="emailTemplateFooter"></quillEditor>
    </div>
    <div
      slot="footer"
      class="mb-12 mt-12"
      v-if="check_has_permission('updateEmailTemplates')"
    >
      <vs-row>
        <vs-button @click="saveEmailDetails" align="left"
          >Save Details</vs-button
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../../Editor";

export default {
  name: "emailViewEditDetail",
  components: {
    quillEditor
  },
  data: () => ({
    pages: {
      content: "",
      footer: "",
      subject: ""
    }
  }),
  methods: {
    ...mapActions("email", ["fetchEmailDetails", "updateEmailDetails"]),
    getEmailDetail(id) {
      let self = this;
      this.fetchEmailDetails(id).then(res => {
        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages.footer = res.data.data.footer;
        this.pages = res.data.data;
      });
    },
    saveEmailDetails() {
      let id = this.$route.params.id;
      let info = this.pages;
      this.updateEmailDetails({ id, info }).then(res => {
        this.$vs.notify({
          subject: "Update Page Content",
          text: res.data.message,
          color: "success"
        });
        //this.$router.push({name: 'email-template'});
      });
    }
  },
  created() {
    let pageId = this.$route.params.id;
    this.getEmailDetail(pageId);
  }
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
ul {
  list-style: none;
  margin-left: 0px;
}
.keywords {
  display: inline-block;
  border: 1px solid dimgrey;
  margin-right: 3px;
  padding: 5px;
  color: white;
  background-color: #3dc9b3;
}
.label {
  font-size: small;
  color: black;
}
.editEmail {
  padding-top: 20px;
  padding-bottom: 20px;
}
.emailCard {
  min-height: 650px;
  padding-left: 20px;
}
</style>
